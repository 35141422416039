/**
 * Exempt an element from Chakra UI theming
 */
.no-chakra {
  all: revert;
  * {
    all: revert;
  }
}

/**
 * Hover utility classes
 */

.hoverable {
  cursor: pointer;
}

.hoverable:hover .show-on-hover {
  opacity: 1;
}

.show-on-hover {
  opacity: 0;
  transition: opacity 0.3s ease;
}

/**
 * Print utility classes
 */
@media print {
  .hide-on-print {
    display: none !important;
  }

  @page {
    margin: 0;
  }

  body {
    margin: 1.6cm;
  }

  .react-grid-item {
    position: unset !important;
    transform: unset !important;
    margin: 0px 10px;
    display: inline-block;

    > div {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }

  .react-grid-layout {
    height: unset !important;
    display: table;
  }
}
