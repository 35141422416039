.react-datepicker {
  font-family: unset;
  font-size: 0.8rem;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.react-datepicker.dark {
  /* border-color: var(--chakra-colors-border-emphasized); */
  background-color: var(--chakra-colors-gray-700);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container > input:disabled {
  background-color: white !important;
  display: block;
  cursor: not-allowed;
}

.react-datepicker__input-container {
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid;
  border-color: #e2e8f0;
}

.react-datepicker__input-container:hover {
  border-color: #cbd5e0;
}
.react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: var(--chakra-colors-blue-500);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: unset;
}

.react-datepicker__today-button {
  background: var(--chakra-colors-gray-50);
  border-top: 1px solid var(--chakra-colors-gray-200);
  border-bottom-left-radius: var(--chakra-radii-md);
  border-bottom-right-radius: var(--chakra-radii-md);
  font-weight: 600;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

/* .react-datepicker__navigation--previous {
          border-right-color: #cbd5e0;
        }

        .react-datepicker__navigation--previous:hover {
          border-right-color: #a0aec0;
        }

        .react-datepicker__navigation--next {
          border-left-color: #cbd5e0;
        }

        .react-datepicker__navigation--next:hover {
          border-left-color: #a0aec0;
        } */

/* ---- HEADER --- */

.react-datepicker__header {
  background: #f7fafc;
}
.dark .react-datepicker__header {
  background: var(--chakra-colors-gray-800);
}

.react-datepicker__header > div:first-child {
  margin: 0 10px !important;
  font-size: 0.9rem;
}
.dark .react-datepicker__header > div:first-child {
  color: white;
}

.dark .react-datepicker__header .chakra-button {
  color: var(--chakra-colors-whiteAlpha-700);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}
.dark .react-datepicker__header,
.dark .react-datepicker__time-container {
  border-color: var(--chakra-colors-border-emphasized);
}

.dark .react-datepicker__day-name {
  color: var(--chakra-colors-whiteAlpha-700);
}

.react-datepicker__day-name {
  margin: 0;
  width: 1.866rem;
  line-height: 1.866rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #edf2f7;
}

.react-datepicker__week {
  margin-bottom: 4px;
}

/* ---- DAY --- */

.react-datepicker__day--in-range:hover {
  background: var(--chakra-colors-gray-200);
  color: black;
  border-radius: 20px;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: var(--chakra-colors-gray-100);
  color: black;
}

.dark .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: var(--chakra-colors-whiteAlpha-100);
  color: white;
}

.react-datepicker__day--selecting-range-end {
  background: var(--chakra-colors-blue-400);
}

.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end:hover {
  background: var(--chakra-colors-blue-600);
  border-radius: 20px;
  color: white;
}

.react-datepicker__day,
.react-datepicker__day:hover {
  margin: 0;
  width: 1.866rem;
  line-height: 1.866rem;
  border-radius: 0px;
}

.react-datepicker__day:hover {
  background: var(--chakra-colors-gray-100);
}

.dark .react-datepicker__day {
  color: white;
}

.dark .react-datepicker__day:hover {
  background: var(--chakra-colors-whiteAlpha-200);
}

.react-datepicker__day--outside-month {
  color: var(--chakra-colors-gray-600);
}

.dark .react-datepicker__day--outside-month {
  color: var(--chakra-colors-gray-400);
}

.react-datepicker__day--disabled {
  color: var(--chakra-colors-blackAlpha-400);
}

.dark .react-datepicker__day--disabled {
  color: var(--chakra-colors-whiteAlpha-400);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-blue-200);
}

.react-datepicker__day--in-range {
  background: var(--chakra-colors-blue-50);
  color: black;
}

.dark .react-datepicker__day--in-range {
  background: #90cdf430;
  color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background: var(--chakra-colors-blue-500) !important;
  color: white !important;
  border-radius: 4px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end:hover {
  border-radius: 4px;
}

.dark .react-datepicker__day--selected,
.dark .react-datepicker__day--range-start,
.dark .react-datepicker__day--range-end {
  background: var(--chakra-colors-blue-300) !important;
  color: var(--chakra-colors-gray-800) !important;
}

.react-datepicker__day--keyboard-selected {
  background: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #2a69ac;
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: hsl(0, 0%, 80%);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: hsl(0, 0%, 70%);
}

.react-datepicker__today-button {
  border-radius: 0 0 0.3rem 0.3rem;
  border-top: 1px solid var(--chakra-colors-chakra-border-color);
  padding: 0.4rem 0;
  background: var(--chakra-colors-gray-100);
}

.react-datepicker__today-button:hover {
  background: var(--chakra-colors-gray-200);
}
